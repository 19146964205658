<script setup lang="ts">
import PageTitle from '~/components/PageTitle.vue'

const { page } = useContent()
</script>

<template>
  <NuxtLayout>
    <article v-if="page">
      <PageTitle />
      <ContentDoc class="nuxt-content" />
    </article>
  </NuxtLayout>
</template>
